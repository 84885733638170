export default function numberWithCommas(x) {
    let onlyNumber = x | 0;
    let onlyDecimals = x - onlyNumber;
    if(onlyDecimals > 0) {
        return x;
    } else {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    
}

function convertTimestampToAmerican(timestamp) {
  const date = new Date(timestamp * 1000);
  const options = { month: 'short', day: 'numeric', year: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}

function convertTimestampToAmericanAmPm(timestamp) {
  const date = new Date(timestamp * 1000);
  const options = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
  return date.toLocaleDateString('en-US', options);
}
/*export default function numberWithCommas(x) {
    //let onlyNumber = x | 0;
    //let onlyDecimals = x - onlyNumber;
    //let onlyNumberAmerica = onlyNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}*/

function timestampToDatetime(x) {
    
    const dateObject = new Date(x);

    const humanDateFormat = dateObject.toLocaleString();
    return humanDateFormat;
}

function roundDecimals(num, decCount) {

  if(num == undefined || num == null || num <= 0 || num == '') {

    return 0;
  }

    let result = num.toString().indexOf(".");
    if(result === -1) {
      return num;
    }
    else {

      let _decCount = num.toString().split(".")[1].length || 0;

      if(_decCount < decCount) {
  
        decCount = _decCount;
      }

      num = parseFloat(num).toFixed(decCount)
      return num;
    }
  }

  function roundNumberIfNeeded(num, decCount) {

    if(num == undefined || num == null || num == '') {

      return 0;
    }

    let decimalPlaces = (num.toString().split('.')[1] || '').length;

    let roundedNumber;

    if (decimalPlaces > decCount) {
        roundedNumber = Number(num.toFixed(decCount));
    } else {
        roundedNumber = num;
    }
    return roundedNumber;
  }

  function checkIfMore8Decimals(num, decCount) {

    if(num == undefined || num == null || num <= 0 || num == '') {

      return false;
    } else {

      let decimalPlaces = (num.toString().split('.')[1] || '').length;

      if (decimalPlaces > decCount) {
          return true;
      } else {
          return false;
      }
    }    
  }

  

  function numberToAmericanFormat(num, decCount) {

    return Intl.NumberFormat('en-IN', { maximumSignificantDigits: decCount }).format(num)
    //return num
  }

  const firstLetterValidation = (val) => {

    let makeZero = false;
    let result = String(val).indexOf("0");
    if(result !== -1) {

        if(result === 0) {
            console.log('first is zero')
            let secondChar = val.substr(1, 1);
            if(secondChar !== '.') {

                makeZero = true;
            }
            console.log(secondChar);
        } else {
            console.log('first is not zero')
        }
    } else {

        console.log('there  is not zero')
    }

    return makeZero;
  }

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }


  

export {firstLetterValidation, numberWithCommas, roundDecimals, numberToAmericanFormat, timestampToDatetime, convertTimestampToAmerican, convertTimestampToAmericanAmPm, capitalizeFirstLetter, roundNumberIfNeeded, checkIfMore8Decimals}